<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        What concentration of
        <chemical-latex content="CrO4^{2-}(aq)" />
        would be found in equilibrium with
        <number-value :value="Agconc" unit="\text{M}" />
        of
        <chemical-latex content="Ag+(aq)" />
        and
        <chemical-latex content="Ag2CrO4(s)" />
        given that the
        <stemble-latex :content="'$\\text{K}_{\\text{sp}}$'" />
        of
        <chemical-latex content="Ag2CrO4" />
        is
        <stemble-latex :content="'$9.00\\times 10^{-12}?$'" />
      </p>

      <calculation-input
        v-model="inputs.CrO4conc"
        :prepend-text="'$\\ce{[CrO4^{2-}]}$'"
        :append-text="'$\\text{M}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question124',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        CrO4conc: null,
      },
    };
  },
  computed: {
    Agconc() {
      return this.taskState.getValueBySymbol('Agconc').content;
    },
  },
};
</script>
